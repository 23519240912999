import React, { Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { TableFlowImporter } from "@tableflow/react";
import clsx from "clsx";

const options = [
  {
    label: "Import Products",
    import_type: "product",
    importer_id: "ec3fdb4c-6b96-4e23-98c2-3d9029977601",
    template: {
      columns: [
        {
          name: "SKU",
          key: "sku",
          required: true,
          description: "SKU or Product Code",
          suggested_mappings: [
            "sku",
            "product_code",
            "part",
            "part_number",
            "part number",
            "model number",
          ],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Manufacturer SKU",
          key: "manufacturer_sku",
          required: false,
          description: "Manufacturer or Supplier part number",
          suggested_mappings: ["supplier_sku", "manufacturer_sku", "mfr"],
          data_type: "string",
        },
        {
          name: "Title",
          key: "title",
          required: true,
          description: "Product Title",
          suggested_mappings: ["title", "name", "product_name", "product name"],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
            {
              validate: "length",
              options: {
                max: 255,
              },
              message: "Title must be less than 255 characters",
            },
          ],
        },
        {
          name: "Description",
          key: "description",
          required: false,
          description: "Product Description",
          suggested_mappings: [
            "description",
            "product_description",
            "product description",
            "body",
            "body_html",
            "body html",
          ],
          data_type: "string",
        },
        {
          name: "Barcode",
          key: "barcode",
          required: false,
          description: "Product Barcode (GTIN13)",
          suggested_mappings: ["barcode", "gtin13", "upc", "ean", "ean13", "gtin"],
          data_type: "number",
        },
        {
          name: "Product Type",
          key: "product_type",
          required: false,
          description: "Can be a TecDoc standard description value or a custom value",
          suggested_mappings: ["product_type", "type", "product type"],
          data_type: "string",
        },
        {
          name: "Price",
          key: "price",
          required: false,
          description: "Product Price",
          suggested_mappings: ["price", "cost", "msrp", "rrp"],
          data_type: "number",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Image URL",
          key: "image_url",
          required: false,
          description: "External product image URL (i.e. Dropbox, AWS S3)",
          suggested_mappings: ["image_url", "image", "image_url_1", "image_1"],
          data_type: "string",
        },
        {
          name: "Part/Assembly",
          key: "record_type",
          required: true,
          description:
            "Is the product a Part or Assembly. An Assembly can have many Parts (e.g. a Kit)",
          suggested_mappings: ["record_type", "record type"],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
            {
              validate: "list",
              options: ["Part", "Assembly"],
              message: "Type must be either Part or Assembly",
            },
          ],
        },
        {
          name: "Published",
          key: "published",
          required: true,
          description: "Is the product available for sale?",
          suggested_mappings: ["published", "visibility"],
          data_type: "boolean",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
      ],
    },
  },
  {
    label: "Import Packaging",
    import_type: "packaging",
    importer_id: "bf1f064e-0faa-45c2-a75c-c6618b30f506",
    template: {
      columns: [
        {
          name: "SKU",
          key: "sku",
          required: true,
          description: "SKU or Product Code",
          suggested_mappings: [
            "sku",
            "product_code",
            "part",
            "part_number",
            "part number",
            "model number",
          ],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Barcode",
          key: "barcode",
          required: false,
          description: "Product Barcode (GTIN13)",
          suggested_mappings: ["barcode", "gtin13", "upc", "ean", "ean13"],
          data_type: "number",
        },
        {
          name: "Identifier",
          key: "label",
          required: false,
          description: "An identifier for the package, not visible to customers, e.g. Box 1.",
          suggested_mappings: ["label", "identifier", "name"],
        },
        {
          name: "Package Type",
          key: "package_type",
          required: true,
          description:
            "Please use one of the following values: Carton, Pallet, Satchel, Tube or Other",
          suggested_mappings: ["type", "package type", "package_type"],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
            {
              validate: "list",
              options: ["Carton", "Pallet", "Satchel", "Tube", "Other"],
              message: "Type must be either Carton, Pallet, Blister Satchel, Tube or Other",
            },
          ],
        },
        {
          name: "Length",
          key: "length",
          required: true,
          description: "",
          suggested_mappings: ["length", "l", "long", "longest"],
          data_type: "number",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Width",
          key: "width",
          required: true,
          description: "",
          suggested_mappings: ["width", "w"],
          data_type: "number",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Height",
          key: "height",
          required: true,
          description: "",
          suggested_mappings: ["height", "h", "high", "highest"],
          data_type: "number",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Dimension Unit",
          key: "dimensions_unit",
          required: true,
          description: "",
          suggested_mappings: ["dimensions_unit", "dimensions unit", "dimensions"],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
            {
              validate: "list",
              options: ["cm", "mm", "m", "in", "ft", "yd"],
              message: "Dimension unit must be cm, mm, m, in, ft or yd",
            },
          ],
        },
        {
          name: "Weight",
          key: "weight",
          required: true,
          description: "",
          suggested_mappings: ["weight", "wgt", "mass"],
          data_type: "number",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Weight Unit",
          key: "weight_unit",
          required: true,
          description: "",
          suggested_mappings: ["weight_unit", "weight unit"],
          data_type: "string",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
            {
              validate: "list",
              options: ["kg", "g", "lb", "oz"],
              message: "Weight unit must be kg, g, lb or oz",
            },
          ],
        },
        {
          name: "Dangerous Goods",
          key: "dangerous_goods",
          required: true,
          description: "",
          suggested_mappings: ["dangerous_goods", "dangerous goods", "dg"],
          data_type: "boolean",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
        {
          name: "Ships As Is",
          key: "ships_separate",
          required: true,
          description:
            "Is the product shipped as is or does it require additional packaging at time of shipping?",
          suggested_mappings: ["ships_separate", "ships separate", "ships_as_is", "ships as is"],
          data_type: "boolean",
          validations: [
            {
              validate: "not_blank",
              message: "Cell must contain a value",
            },
          ],
        },
      ],
    },
  },
  // {
  //   label: "Import Media",
  //   import_type: "Media",
  //   importer_id: "13f40548-96a8-4bb3-80c8-1f7c1d5cbc32",
  //   template: null,
  // },
  {
    label: "Import Vehicle Fitments",
    import_type: "product_vehicles",
    importer_id: "b58fb7ea-5f78-4ba2-a4c6-3e8cfe848023",
    template: null,
  },
  {
    label: "Import Product Attributes",
    import_type: "product_attributes",
    importer_id: "9faa7746-89d3-4ae4-8857-3086463aa962",
    template: null,
  },
];

export default function ProductImport({ user, team, brand }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <>
      <Menu as="div" className="relative ml-3 inline-block text-left">
        <div>
          <Menu.Button className="btn btn-info">
            Import
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option) => (
                <Menu.Item key={option.importer_id}>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedOption(option);
                        setIsOpen(true);
                      }}
                      className={clsx(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm disabled:text-gray-300",
                      )}
                      // disabled={option.template === null}
                    >
                      {option.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {selectedOption && selectedOption?.importer_id && (
        <TableFlowImporter
          importerId={selectedOption.importer_id}
          modalIsOpen={isOpen}
          modalOnCloseTriggered={() => setIsOpen(false)}
          darkMode={false}
          // template={selectedOption.template}
          metadata={{
            user_id: user.id,
            team_id: team.id,
            brand_id: brand.id,
            import_type: selectedOption.import_type,
          }}
          onComplete={(data, error) => {
            console.log(data);

            if (error) {
              console.log(error);
              toast.error(error);
            } else {
              setIsOpen(false);
              toast.success(
                "Import successful. You'll receive an email when we've finished processing your data.",
              );
            }
          }}
        />
      )}

      <Toaster />
    </>
  );
}
